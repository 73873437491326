.carousel .slide {
  max-height: 100vh;
  height: 100%;
  max-width: 100vw;
  width: 100%;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    background: transparent linear-gradient(234deg, #27334200 0%, #1A222C6E 61%, #151C24 100%) 0% 0% no-repeat padding-box;
    width: 100%;
    height: 100%;
  }
}