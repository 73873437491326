.md-text {
  margin: 50px 0;
  font-size: 16px;
  line-height: 32px;
  
  h2 {
    font-size: 36px;
    line-height: 1.24em;
    font-weight: bold;
    margin: 30px 0;
  }

  h3 {
    font-size: 28px;
    line-height: 1.24em;
    font-weight: bold;
    margin: 0 0 15px;
  }

  p {
    margin: 0 0 15px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ul {
    margin-left: 15px;
    margin-bottom: 15px;
    padding-left: 15px;
    list-style-type: disc;
  }

  @media screen and (max-width: 980px) {
    h2 { font-size: 26px; }
    h3 { font-size: 22px; }
  }
}