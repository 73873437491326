/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local(''), url('google/roboto-v30-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('google/roboto-v30-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('google/roboto-v30-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('google/roboto-v30-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local(''), url('google/roboto-v30-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('google/roboto-v30-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('google/roboto-v30-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('google/roboto-v30-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local(''), url('google/roboto-v30-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('google/roboto-v30-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('google/roboto-v30-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('google/roboto-v30-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('google/roboto-v30-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('google/roboto-v30-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local(''), url('google/roboto-v30-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('google/roboto-v30-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local(''), url('google/roboto-v30-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('google/roboto-v30-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Fave';
  src: local('Fave Script'), local('Fave-Script'), url('Fave-Script.woff2') format('woff2'), url('Fave-Script.woff') format('woff'), url('Fave-Script.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'Interstate Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Interstate Bold'), url('Interstate-Bold.otf') format('otf');
}

@font-face {
  font-family: 'Interstate Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Interstate Regular'), url('Interstate-Regular.otf') format('otf');
} */

@font-face {
  font-family: 'Avenir';
  src: url('Avenir-Light.eot');
  src: local('Avenir Light'), local('Avenir-Light'), url('Avenir-Light.eot?#iefix') format('embedded-opentype'), url('Avenir-Light.woff2') format('woff2'), url('Avenir-Light.woff') format('woff'),
    url('Avenir-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Regular.eot');
  src: local('Inter Regular'), local('Inter-Regular'), url('Inter-Regular.eot?#iefix') format('embedded-opentype'), url('Inter-Regular.woff2') format('woff2'), url('Inter-Regular.woff') format('woff'),
    url('Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* red hat display - regular */
@font-face {
  font-family: 'Red Hat Display';
  src: url('google/RedHatDisplay-Regular.eot');
  src: local('Red Hat Display'), local('RedHatDisplay-Regular'), url('google/RedHatDisplay-Regular.eot?#iefix') format('embedded-opentype'), url('google/RedHatDisplay-Regular.woff2') format('woff2'),
    url('google/RedHatDisplay-Regular.woff') format('woff'), url('google/RedHatDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* red hat display - semibold */
@font-face {
  font-family: 'Red Hat Display';
  src: url('google/RedHatDisplay-Medium.eot');
  src: local('Red Hat Display Medium'), local('RedHatDisplay-Medium'), url('google/RedHatDisplay-Medium.eot?#iefix') format('embedded-opentype'),
    url('google/RedHatDisplay-Medium.woff2') format('woff2'), url('google/RedHatDisplay-Medium.woff') format('woff'), url('google/RedHatDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* red hat display - bold */
@font-face {
  font-family: 'Red Hat Display';
  src: url('google/RedHatDisplay-Bold.eot');
  src: local('Red Hat Display Bold'), local('RedHatDisplay-Bold'), url('google/RedHatDisplay-Bold.eot?#iefix') format('embedded-opentype'), url('google/RedHatDisplay-Bold.woff2') format('woff2'),
    url('google/RedHatDisplay-Bold.woff') format('woff'), url('google/RedHatDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
