*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

#root {
  height: inherit;
}

body {
  margin: 0;
  font-family: 'Red Hat Display';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1d1d1d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (max-width: 980px) {
  body {
    letter-spacing: 0.45px;
    font-size: 18px;
    line-height: 28px;
  }
}

img {
  width: 100%;
  height: auto;
}

.strong {
  display: block;
  font-weight: 500;
}

@keyframes pulse {
  0% {
    transform: scale(0.75);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.75);
  }
}

@keyframes smoothOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
