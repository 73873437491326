#dropin-container {
  width: 100%;
  margin: 0 auto;
}

.adyen-checkout__button__icon {
  margin-right: 12px;
  width: unset;
}

.adyen-checkout__status__icon {
  width: 100px;
}

.checkbox-label {
  font-size: 18px;
}

@media (max-width: 700px) {
  .checkbox-label {
    font-size: 14px;
    line-height: 20px;
  }
  #dropin-container {
    font-size: 70%;
  }
  .form-check .form-check-input {
    width: 20px;
    height: 20px;
  }
}

.adyen-checkout__label__text {
  overflow: unset;
}

.adyen-checkout__card__brands__brand-wrapper img {
  margin-bottom: 7px;
}
