.react-switch-selector-wrapper::before {
  border: 1px solid #91a1b7 !important;
}

.form-control.is-invalid {
  background-image: url('../assets/icons/info-icon.svg');
  background-position: right 24px center;
  background-size: 28px 28px;
}

.form-check-input {
  width: 25px;
  height: 25px;
}

.form-check-input:checked {
  background-color: unset;
  border-color: #42546e;
}

.form-check-input:focus {
  box-shadow: unset;
  border-color: #42546e;
}

.form-check-input:checked[type='checkbox'] {
  background-image: url('../assets//icons/checked-icon.svg');
}

.form-control:focus {
  border-color: unset;
  box-shadow: unset;
}

.form-label {
  font-size: 14px;
  margin-bottom: 0;
}
