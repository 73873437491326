.certificate-logo {
  position: absolute;
  right: 14%;
  top: 25px;
  width: 150px;
  z-index: 10;
  transform: rotate(13deg);
}
@media screen and (max-width: 1400px) {
  .certificate-logo {
    right: 7%;
  }
}
@media screen and (max-width: 991px) {
  .certificate-logo {
    top: 35px;
    right: 50%;
    margin-right: -75px;
  }
}
