.switch-wrapper {
  width: 265px;
  height: 54px;
  margin-bottom: 20px;
  text-align: center;
}

.invalid--red-color {
  color: var(--red-color);
}

.padding-left-10 {
  padding-left: 10px;
}

.under-input-text {
  font-size: 12px;
}

.valid-form {
  height: 60px;
  border-color: var(--lightGrey-color);
}

.invalid-form {
  height: 60px;
  border-color: var(--red-color);
  background-color: #faf0f4;
}

.form-control.is-invalid.invalid-form-password {
  height: 60px;
  border-color: var(--red-color);
  background-color: #faf0f4;
  background-image: unset;
}

.eye-wrapper {
  position: absolute;
  width: 17px;
  height: 12px;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.eye-icon {
  background-image: url('../../assets/icons/eye-icon.svg');
}

.crossed-out-eye-icon {
  background-image: url('../../assets/icons/crossed-out-eye.svg');
  top: 23px;
  height: 15px;
}

.submit-button {
  width: 450px;
  height: 75px;
}

.edit-icon {
  width: 26px;
  height: 26px;
  margin-left: 25px;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.green-button {
  width: 450px;
  height: 75px;
  font-size: 36px;
  line-height: 75px;
  font-weight: 400;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 30px 20px;
  background-color: white;
  box-shadow: 24;
}

@media (max-width: 960px) {
  .green-button {
    width: 250px;
    height: 45px;
    font-size: 26px;
    line-height: 45px;
  }
  .modal-box {
    width: 100%;
  }
}
