:root {
  --blackberry-color: #5a315e;
  --plum-color: #781867;
  --darkPlum-color: #721762;
  --lightGrey-color: #cad2dd;
  --warmGrey-color: #91a1b7;
  --darkGrey-color: #44556c;
  --white-color: #fff;
  --black-color: #1d1d1d;
  --red-color: #f54582;
  --blue-color: #4090f2;
  --cubeBox-color: #e9edf2;
  --background-color: #f7f7f8;
  --attention-color: #d4fd34;
  --noLimit-color: #eb7f00;
}
