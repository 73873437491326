.mobile-cube-form-wrapper {
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Red Hat Display' !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #1d1d1d !important;
  text-align: left !important;
  background-color: #f7f7f8;

  .mobile-cube-container {
    width: 100% !important;
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
    margin-right: auto !important;
    margin-left: auto !important;

    .bg-light {
      background-color: #f7f7f8 !important;
    }

    .jumbotron {
      padding-left: 0 !important;
      padding-right: 0 !important;
      border-radius: 0 !important;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important;
    }

    .infotext {
      margin: 2rem auto !important;
    }

    h5 {
      text-transform: uppercase !important;
      font-weight: 500 !important;
      font-size: 18px !important;
      margin-bottom: 10px !important;
    }

    h2 {
      line-height: 38px !important;
      margin-bottom: 10px !important;
      font-size: 26px !important;
      font-weight: bold !important;
      margin-top: 0 !important;
    }

    h3 {
      line-height: 24px !important;
      margin-bottom: 10px !important;
      font-size: 20px !important;
      font-weight: bold;
    }

    h4 {
      font-size: 18px !important;
      font-weight: 500 !important;
      text-transform: uppercase !important;
      margin-bottom: 10px !important;
      line-height: 1.5em !important;
    }

    .description-item {
      border: 0;
      padding-bottom: 1.5rem;
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: transparent;
      background-clip: border-box;
    }

    .description-header {
      border-bottom: 1px solid #dee8e3;
    }

    .description-header-text {
      text-align: left;
      display: inline-block;
      border-bottom: none !important;
      text-transform: uppercase;
      font-size: 18px !important;
      font-weight: 500 !important;
      width: 90%;
      line-height: 26px !important;
      letter-spacing: 0.9px;
    }

    .description-body {
      padding: 1.5rem 0px;
    }

    ul {
      margin-top: 0 !important;
      margin-bottom: 1rem !important;
      padding-left: 40px !important;
      display: block !important;
      list-style-type: disc !important;
      margin-block-start: 1em !important;
      margin-block-end: 1em !important;
      margin-inline-start: 0px !important;
      margin-inline-end: 0px !important;
      padding-inline-start: 40px !important;
    }

    ul > li + li {
      margin-top: 15px;
    }

    .product-detail-name {
      font-family: 'Red Hat Display' !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 26px !important;
      letter-spacing: 0.9px;
    }

    .product-detail-description-text {
      margin-bottom: 1.5rem !important;
    }

    .product-detail-ordernumber-container {
      margin-bottom: 0 !important;
    }

    .bold {
      font-weight: 700;
    }

    .li-text {
      margin-top: 15px;
      font-weight: 400;
    }

    .margin-top {
      margin-top: 15px;
    }

    .table {
      font-family: 'Red Hat Display' !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 26px !important;
    }

    .table-th1 {
      text-align: left;
      min-width: 175;
      font-weight: 500;
      line-height: 22px !important;
    }

    .table-th2 {
      text-align: left;
      min-width: 105px;
      font-weight: 500;
      line-height: 22px !important;
    }

    .table-th3 {
      text-align: left;
      min-width: 100px;
      font-weight: 500;
      line-height: 22px !important;
    }

    .table thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee8e3;
    }

    .table th,
    .table td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee8e3 !important;
    }

    .small-line-height {
      line-height: 19.6px;
    }

    .image-space {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }

    .image-container {
      margin-right: -1.25rem !important;
    }
  }
}
